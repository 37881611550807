import React from 'react';

import { Layout } from '@layouts';
import { Hero, News, AuctionSection } from '@components';
import { useSiteSettings, useContact, useAuctionSection } from '@queries';

const IndexPage = () => {
  const { heroImg, _rawNews } = useSiteSettings();
  const { title, name, institution, idEpu, phoneNumber, email } = useContact();
  const auctions = useAuctionSection();

  return (
    <Layout>
      <Hero
        heroImg={heroImg}
        title={title}
        name={name}
        institution={institution}
        phoneNumber={phoneNumber}
        email={email}
        idEpu={idEpu}
      />
      <News news={_rawNews} />
      {!!auctions.length && <AuctionSection auctions={auctions} />}
    </Layout>
  );
};

export default IndexPage;
